// assets
import logo from '../images/logo.svg';
import test from '../images/linkedin_icon.png';
import loader from '../images/loader-64x/Preloader_6.gif';
import boneartis_banner from '../images/boneartis-banners.png';
import footer_image from '../images/logo-footer.png';

var $ = jQuery.noConflict();

$(window).scroll(function(){
    const sticky = $('#ba-navigation-container');
    let scroll = $(window).scrollTop();

    if (scroll >= 100) sticky.addClass('fixed');
    else sticky.removeClass('fixed');
});

jQuery(window).on('load', function() {

    upButton("#button_up");
    jQuery(".se-pre-con").fadeOut("slow");
});



$('#dismiss, .overlay').on('click', function () {
    $('#sidebar').removeClass('active');
    $('.overlay').removeClass('active');
    $('body').removeClass('sidebar-active');
});

$('#sidebarCollapse').on('click', function () {
    $('body').addClass('sidebar-active');
    $('#sidebar').addClass('active');
    $('.overlay').addClass('active');
    $('#sidebarCollapse .collapse.in').toggleClass('in');
    $('#sidebarCollapse a[aria-expanded=true]').attr('aria-expanded', 'false');
});


$('#sidebar #menu-navigation').find('.dropdown').each(function() {
    $(this).children('a').each(function() {
        var url = $(this).attr('href');
        if(url != '#') {
            $(this).attr('data-url',url);
            $(this).attr('href','#');
        }
    });
    $(this).children('a').click(function(e) {
        e.preventDefault();
    });

    if($(this).closest('.navbar-list-vertical').css('display') !== 'none') { // only on collapsed nav
        $(this).on({
            "click" : function(e) {
                if(e.target == $(this).children('a').first().get(0)) {
                    if($(this).children('.dropdown-menu').hasClass('show')) {
                        var url = $(this).children('a').first().attr("data-url");

                        // alert('Redirecting to: ' + url);
                        document.location.href = url
                    } else {
                        $(this).children('.dropdown-menu').addClass('show');

                    }
                    e.stopImmediatePropagation();
                    e.preventDefault();
                    return false;
                }
            },
            "hide.bs.dropdown" : function(e) {
                e.preventDefault();
                return false;
            }
        });
    }
});



function upButton(element){
    var btn = $(element);

    $(window).scroll(function() {
        if ($(window).scrollTop() > 300) {
            btn.addClass('show');
        } else {
            btn.removeClass('show');
        }
    });

    btn.on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({scrollTop:0}, '300');
    });

}